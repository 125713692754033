label {
    display: block;
    margin-bottom: 0.5rem;
  }
  
  select {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  input[type='checkbox'] {
    margin-right: 0.5rem;
  }
  
  button[type='submit'] {
    display: block;
    margin-top: 0.5rem;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    background-color: #007bff;
    border: 1px solid #007bff;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  button[type='submit']:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
  }

  .UI-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  div[disabled]
{
  pointer-events: none;
  opacity: 0.4;
}
  

.loading-div{
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 1;
}

