.Rectangle-21 {
    display: flex;
    flex-direction: row;
    width:100%;
    min-height: 50px;
    margin-bottom: 40px;
    background-color: #2d3748;
  }
.signUp{
  width: 85px;
  margin-left: auto;
  margin-right: 20px;
  margin-top: 5px;
}

.appLogo{
  width: 120px;
  margin-left: 20px;
  margin-top: 4px;
}
